<template>
  <v-container fluid>
    <v-row>
      <v-col class="col-12">
        <h1>
          Jobs awaiting approval
          <span class="float-right">
            Total value: 
            {{ total | priceInPounds | currency }}
          </span>
        </h1>
        <p>These jobs have been marked as complete but not yet approved</p>
        <v-simple-table
          fixed-header
          height="450px"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Job No</th>
                <th class="text-left">Customer</th>
                <th class="text-left">Date</th>
                <th class="text-left">Date Completed</th>
                <th class="text-left">Site</th>
                <th class="text-left">Value</th>
                <th class="text-left">By</th>
                <th class="text-left">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(job, index) in jobs"
                :key="index"
              >
                <td>{{ job.jobNumber }}</td>
                <td>{{ job.customer }}</td>
                <td>{{ job.date | tinyDate }}</td>
                <td>{{ job.completedDate | tinyDate }}</td>
                <td>{{ job.site }}</td>
                <td>
                  <span v-if="job.value > job.minChargeAmount">
                    {{ job.value | priceInPounds | currency }}
                  </span>
                  <span v-else>
                    {{ job.minChargeAmount | priceInPounds | currency }}
                  </span>
                </td>
                <td>{{ job.quotedByInitials }}</td>
                <td class="pr-0">
                  <router-link custom v-slot="{ navigate }" :to="`/live-jobs/overview/${job.id}`">
                    <v-btn
                      title="View"
                      x-small
                      @click="navigate"
                      @keypress.enter="navigate"
                      role="link">
                        View
                    </v-btn>
                  </router-link>
                  &nbsp;
                  <v-btn
                      title="Approve"
                      x-small
                      @click="confirmApprove(index)">
                        Approve
                    </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-pagination
          class="pt-4"
          v-model="page"
          :length="length"
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
          :total-visible="totalVisible"
        ></v-pagination>
      </v-col>
    </v-row>
    <v-dialog
      v-model="approveBox"
      max-width="800"
    >
      <v-card>
        <v-card-title>
          Confirm Approval of job: {{ selectedJob.jobNumber }}
        </v-card-title>
        <v-card-text class="pt-4">
          Important, please confirm you have reviewed this job and it is correct
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green lighten-2"
            small
            @click="approve"
          >
            {{ $store.state.username }} Approving Job for Invoice
          </v-btn>
          &nbsp;
          <v-btn
            small
            @click="cancelApproving"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'QuotationsIndex',
  computed: {
    token() {
      return this.$store.state.token;
    },
    userid() {
      return this.$store.state.userid;
    },
  },
  data() {
    return {
      jobs: [],
      selectedJob: {
        id: 0,
      },
      page: 1,
      length: 0,
      totalVisible: 9,
      total: 0,
      approveBox: false,
    };
  },
  watch: {
    page() {
      this.getJobs();
    },
  },
  methods: {
    getJobs() {
      axios.get(`/liveJobs/getCompleteNotAuthorised/${this.page}/${this.userid}.json?token=${this.token}`)
        .then((response) => {
          this.jobs = response.data.jobs;
          this.length = response.data.length;
          this.total = response.data.total;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    confirmApprove(index) {
      this.selectedJob = this.jobs[index];
      this.approveBox = true;
    },
    cancelApproving() {
      this.selectedJob = { id: 0 };
      this.approveBox = false;
    },
    approve() {
      const jobId = this.selectedJob.id;
      axios.get(`/quotations/approveForInvoice/${jobId}/${this.userid}.json?token=${this.token}`)
        .then(() => {
          this.jobs = [];
          this.getJobs();
          this.approveBox = false;
          this.selectedJob = {};
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getJobs();
  },
};
</script>
